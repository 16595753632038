import React from "react"
import LatestPosts from "../components/LatestPosts/latestPosts"
import Header from "../components/Header/header"
import HeaderMobile from "../components/Header/headerMobile"
import Footer from "../components/Footer/footer"

const CategoryPage = () => (
  <>
  <Header />
  <HeaderMobile />
  <div className="content">
    <div className="container">
      <LatestPosts />
    </div>
  </div>
  <Footer />
  </>
)

export default CategoryPage
