import React, {Component} from 'react'

export default class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="container">
        <h3 className="logo-amz">Amazing<span className="logo-amz-blue">Tec</span></h3>
        <div className="footer-items">
          <div className="col align-mobile align-first-dsk">
            <span className="footer-item-first">Mídia Kit</span>
          </div>
          <div className="col align-mobile align-center-dsk">
            <span className="footer-item">Política de Privacidade</span>
          </div>
          <div className="col align-mobile align-last-dsk">
            <span className="footer-item-last">Contato</span>
          </div>
        </div>
          <div className="social-icons">
              <div className="col-6 align-first">
                <a href="https://facebook.com/amazingtec" target="_blank"
                   rel="noopener, nofollow" className="la-social-icons la-social-icons-first">
                  <i className="la la-facebook-official la-social-icons-color" />
                </a>
              </div>
              <div className="col-6 align-last">
                <a href="https://instagram.com/amazingtec" target="_blank" rel="noopener, nofollow"
                   className="la-social-icons la-social-icons-last">
                  <i className="la la-instagram la-social-icons-color" />
                </a>
              </div>
          </div>
        </div>
      </div>
    )
  }
}
