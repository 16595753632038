import { Link } from "gatsby"
import React, { Component } from "react"

export default class HeaderMobile extends Component {
  constructor(props){
    super(props);
    this.state={
      open: this.props.open? this.props.open:false,
      color: this.props.color ? this.props.color:'black',
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.open !== this.state.open){
      this.setState({open:nextProps.open});
    }
  }

  handleClick(){
    this.setState({open:!this.state.open});
  }

  render() {
    const styles = {
      container: {
        marginTop: '7px',
        height: '32px',
        width: '32px',
        display:'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        padding: '4px',
      },
      line: {
        height: '2px',
        width: '20px',
        background: 'var(--main-text-color)',
        transition: 'all 0.2s ease',
      },
      lineTop: {
        transform: this.state.open ? 'rotate(45deg)':'none',
        transformOrigin: 'top left',
        marginBottom: '5px',
      },
      lineMiddle: {
        opacity: this.state.open ? 0: 1,
        transform: this.state.open ? 'translateX(-16px)':'none',
      },
      lineBottom: {
        transform: this.state.open ? 'translateX(-1px) rotate(-45deg)':'none',
        transformOrigin: 'top left',
        marginTop: '5px',
      },
    }
    return (
      <div className="d-block d-sm-block d-md-block d-lg-none d-xl-none">
        <div className={this.state.open ? "menu isMenuActive" : "menu" }>
          <div className="container">
            <ul className="menu-mobile">
              <li className="menu-mobile-item">
                <Link to="/noticias/" className="menu-mobile-link">Notícias</Link>
              </li>
              <hr className="menu-mobile-divider" />
              <li className="menu-mobile-item">
                <Link to="/reviews/" className="menu-mobile-link">Reviews</Link>
              </li>
              <hr className="menu-mobile-divider" />
              <li className="menu-mobile-item">
                <Link to="/android/" className="menu-mobile-link">Android</Link>
              </li>
              <hr className="menu-mobile-divider" />
              <li className="menu-mobile-item">
                <Link to="/apple/" className="menu-mobile-link">Apple</Link>
              </li>
              <hr className="menu-mobile-divider" />
              <li className="menu-mobile-item">
                <Link to="/jogos/" className="menu-mobile-link">Jogos</Link>
              </li>
              <hr className="menu-mobile-divider" />
              <li className="menu-mobile-item">
                <Link to="/ti/" className="menu-mobile-link">TI</Link>
              </li>
              <hr className="menu-mobile-divider" />
              <li className="menu-mobile-item">
                <Link to="/windows/" className="menu-mobile-link">Windows</Link>
              </li>
              <hr className="menu-mobile-divider" />
              <li className="menu-mobile-item">
                <Link to="/quem-somos/" className="menu-mobile-link">Quem Somos</Link>
              </li>
              <hr className="menu-mobile-divider" />
              <li className="menu-mobile-item">
                <Link to="/media-kit/" className="menu-mobile-link">Media Kit</Link>
              </li>
              <hr className="menu-mobile-divider" />
              <li className="menu-mobile-item">
                <Link to="/contato/" className="menu-mobile-link">Contato</Link>
              </li>
              <hr className="menu-mobile-divider-last " />
            </ul>
          </div>
        </div>
        <div className="header-mobile">
          <div className="container">
            <div className="col-lg-12 pr-0">
              <div className="row">
                <div className="col">
                  <div style={styles.container}
                       onClick={this.props.collapsed ? this.props.onClick:
                         ()=> {this.handleClick();}}>
                    <div style={{...styles.line,...styles.lineTop}}/>
                    <div style={{...styles.line,...styles.lineMiddle}}/>
                    <div style={{...styles.line,...styles.lineBottom}}/>
                  </div>
                </div>
                <div className="col">
                  <Link to="/" style={{textDecoration: "none"}}>
                    <p className="logo-amz">Amazing<span className="logo-contrast">Tec</span></p>
                  </Link>
                </div>
                <div className="col">

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
